export enum Spec {
  AllTabDisabled = 'specs.siteSearch.AllTabDisabled',
  AppControllerLayoutFix = 'specs.siteSearch.AppControllerLayoutFix',
  Autocomplete = 'specs.siteSearch.Autocomplete',
  ForumFacets = 'specs.siteSearch.ForumFacets',
  ForumTabUpdate = 'specs.siteSearch.ForumTabUpdate',
  HideEmptyTabs = 'specs.siteSearch.HideEmptyTabs',
  ImageResizeOptions = 'specs.siteSearch.ImageResizeOptions',
  Loadable = 'specs.siteSearch.Loadable',
  ManageSearchResultsGfpp = 'specs.siteSearch.ManageSearchResultsGfpp',
  NewApiURL = 'specs.siteSearch.NewApiURL',
  NewResultsPerPageDefaultValue = 'specs.siteSearch.NewResultsPerPageDefaultValue',
  NewStoresAppDefId = 'specs.siteSearch.NewStoresAppDefId',
  ProGallery = 'specs.siteSearch.ProGallery',
  RemoveDuplicateControllers = 'specs.siteSearch.RemoveDuplicateControllers',
  ScrollToWidget = 'specs.siteSearch.ScrollToWidget',
  SeoHiddenPages = 'specs.siteSearch.SeoHiddenPages',
  SuggestionsNoDelay = 'specs.siteSearch.SuggestionsNoDelay',
  ThunderboltCssOptimization = 'specs.siteSearch.ThunderboltCssOptimization',
  TrendingItems = 'specs.siteSearch.TrendingItems',
  UpdatedSettingsUX = 'specs.siteSearch.UpdatedSettingsUX',
  UseWarmupData = 'specs.siteSearch.UseWarmupData',
  SearchQuerySync = 'specs.siteSearch.SearchQuerySync',
  UpdatedPaginationUX = 'specs.siteSearch.UpdatedPaginationUX',
  RemoveManagingAppDefId = 'specs.siteSearch.RemoveManagingAppDefId',
  TrimMultilingualUrl = 'specs.siteSearch.TrimMultilingualUrl',
}
